import { IGetChildrenPayload } from '@/store/actions';
import { IMenuItem } from '@/store/state';
import axios from 'axios'
import qs from 'query-string'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}



export const getMenuItems = (searchURL: string, params: IGetChildrenPayload): Promise<IMenuItem[]> => {
    let url = `${searchURL}&${qs.stringify({
        id: params.id,
        level: params.level
    })}`
    
    return axios.get(url, { headers })
        .then((response:any) => {
            console.log('in axios get: ',response);
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}